// in the instance the main bundle fails to execute
// (e.g. from a browser specific syntax error) this onerror handler
// attempts to log to rollbar
import * as rollbar from '~/api/rollbar/rollbar'

window.addEventListener('error', e => {
    // e is an ErrorEvent not an Error so we can't just directly use it
    const {message, filename, lineno, colno, error} = e

    // if filename is supplied, check it's not for a chrome extension
    // despite the typing it appears filename can sometimes be undefined
    const ourSourceFile = filename ? filename.indexOf('chrome-extension') === -1 : true

    // the event handler gets called for ALL errors, but we only want to log to rollbar when
    // otherwise wouldn't have because of a failure to set up rollbar in the main bundle
    if (!rollbar.checkIfInitialised() && ourSourceFile) {
        rollbar.initRollbar()
        rollbar.sendError(`Main execution failed: ${message}`, {filename, lineno, colno, error})
    }
})
